<template>
  <td class="text-center text-body-1">
    <v-layout wrap class="initials-row">
      <template ma-1 v-for="doctor in value">
        <v-tooltip :key="doctor.id" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-layout ma-1 v-on="on" v-bind="attrs" shrink>
              <RoundInitialsButton
                @clicked="$emit('clicked', header, item, doctor)"
                :size="30"
                :name="nameExtractor(doctor)"
              ></RoundInitialsButton>
            </v-layout>
          </template>
          <span>{{ nameExtractor(doctor) }}</span>
        </v-tooltip>
      </template>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {
      type: Array,
      default: () => [],
    },
    nameExtractor: {
      type: Function,
      default: (doctor) => doctor.name,
    },
  },
  components: {
    RoundInitialsButton: () =>
      import("@/components/buttons/RoundInitialsButton"),
  },
};
</script>

<style lang="scss" scoped>
.initials-row {
  min-width: 76px !important;
}
</style>